<template>
  <div class="PersonalInformation">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="昵称" prop="nickName">
        <el-input v-model="ruleForm.nickName"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="ruleForm.sex" placeholder="请选择性别">
          <el-option label="男" value="1"></el-option>
          <el-option label="女" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职业" prop="occupation">
        <el-radio-group v-model="ruleForm.occupation">
          <el-radio label="个体经营者"></el-radio>
          <el-radio label="企业管理者"></el-radio>
          <el-radio label="职业经理人"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="地区" prop="country">
        <el-cascader
         style="width:100%;"
          v-model="ruleForm.country"
          :options="$store.state.area.areaList"
        />
      </el-form-item>
      <el-form-item style="display: flex;justify-content:end;margin-top:40px">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { userUpDate } from "@/api";
export default {
  data() {
    return {
      ruleForm: {
        nickName: "",
        sex: "",
        occupation: "",
        country: null,
      },
      rules: {
        nickName: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 8 个字符", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        occupation: [
          { required: true, message: "请选择职业", trigger: "change" },
        ],
        country: [{ required: true, message: "请选择地区", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.dataInit();
  },
  methods: {
    dataInit() {
      let { nickName, sex, occupation, address,userId,userName } = this.userInfo;
      let arr = address.split("-")
      this.ruleForm = {
        nickName,
        sex,
        occupation,
        country:arr,
        userId,
        userName,
      }; 
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.country) {
            this.ruleForm.address = JSON.parse(
              JSON.stringify(this.ruleForm.country)
            );
            this.ruleForm.address = this.ruleForm.address.join("-");
            this.ruleForm.userName = this.userInfo.userName
            userUpDate("",this.ruleForm).then(({ code, msg }) => {
              if (code == 200) {
                this.$message.success(msg);
                this.$store.dispatch("getUserInfo");
              } else {
                this.$message.error(msg);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //  this.areaList = this.$store.state.area.areaList;
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.PersonalInformation {
  margin-top: 20px;
  margin-left: 30px;
  width: 600px;
}
</style> 
<style lang="less">
.el-checkbox-button__inner, .el-radio {
 font-weight: 400;
}
</style> 